import { create } from "zustand";

type ClientSessionStore = {
  crmAccount: null | string;
  setClient: (crmAccount: string) => void;
  clearClient: () => void;
};

export const useClientSessionStore = create<ClientSessionStore>((set) => ({
  crmAccount: null,
  setClient: (crmAccount: string) => {
    set({ crmAccount });
  },
  clearClient: () => {
    set({ crmAccount: null });
  },
}));
