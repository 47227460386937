import { createTheme } from "@mui/material/styles";
import red from "@mui/material/colors/red";
import yellow from "@mui/material/colors/yellow";
import grey from "@mui/material/colors/grey";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#003D50",
      dark: "#000000",
      contrastText: "#B5B5B5",
    },
    secondary: {
      light: "#68C6C3",
      main: "#306671",
      dark: "#CACDCD",
      contrastText: "#42B8B4",
    },
    error: {
      light: red[400],
      main: "#E83C4E",
      dark: red[300],
      contrastText: grey[800],
    },
    success: {
      main: "#008475",
      contrastText: "#FFCD21",
    },
    warning: {
      main: yellow[500],
      contrastText: grey[800],
    },
    info: {
      light: "#04AFC3",
      main: "#336473",
      dark: "#595958",
      contrastText: "#7E8081",
    },
    text: {
      primary: grey[900],
      secondary: "#99B1B9",
      disabled: "#C0CDD1",
    },
    action: {
      active: grey[600],
      activatedOpacity: 1,
      disabled: grey[700],
      disabledBackground: grey[200],
      hover: grey[300],
      hoverOpacity: 0.7,
      focus: grey[600],
      focusOpacity: 1,
      selected: grey[300],
      selectedOpacity: 0,
    },
    background: {
      default: "#ffffff",
      // this also controls table rows background color
      paper: grey[200],
    },
    common: {
      black: "black",
      white: "white",
    },
    tonalOffset: 0.2,
  },
  // default values
  // breakpoints: {
  // 	values: {
  // 		xs: 0,
  // 		sm: 600,
  // 		md: 900,
  // 		lg: 1200,
  // 		xl: 1536,
  // 	},
  // },
  typography: {
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontWeightRegular: 0,
    fontFamily: "Open Sans",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
					@font-face {
						font-family: 'Open Sans';
						src: url("/sf-text-light.woff") format('woff');
						font-weight: 300;
						font-style: normal;
					}
				
					@font-face {
						font-family: 'Open Sans';
						src: url("/sf-text-regular.woff") format('woff');
						font-weight: 400;
						font-style: normal;
					}
				`,
    },
  },
  // also can convert pixel to rem
  spacing: (factor: number) => `${0.5 * factor}rem`,
});

export default theme;
