import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { decodeToken } from "react-jwt";
import moment from "moment";
import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { navigateToUrl } from "single-spa";
import { Loading } from "../molecules";

type Props = {
  appId: string;
  token: string;
  setToken: (token: string) => void;
};

const Protected: FC<PropsWithChildren<Props>> = (props) => {
  const { children, appId, token, setToken } = props;
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = window.location;

  const request = {
    account: accounts[0],
    scopes: [`openid offline_access ${appId}`],
  };
  const errorPage = {
    account: accounts[0],
    postLogoutRedirectUri: "/error",
  };
  const logoutRequest = {
    account: accounts[0],
    postLogoutRedirectUri: "/",
  };

  useIdleTimer({
    timeout: 3600000, // 43200000 => 12h in ms | 3600000 => 1h in ms
    onIdle: handleIdle,
  });

  function handleIdle() {
    sessionStorage.clear();
    setToken("null");
  }

  useEffect(() => {
    if (inProgress === "none" && accounts.length > 0) {
      // Retrieve an access token

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          if (response.accessToken) {
            const decoded = decodeToken<any>(response.accessToken);
            if (
              // decoded.groups.length > 0 &&
              // decoded.groups.includes('Client Dashboard')
              decoded.companyId
            ) {
              if (location.pathname !== "/") {
                localStorage.setItem("entry", location.pathname);
              }
              setToken(response.accessToken);
              return;
            }
            instance.logout(errorPage);
          }
        })
        .catch((error) => {
          instance.logoutRedirect(logoutRequest);
        });
    }
    // eslint-disable-next-line
  }, [inProgress, accounts]);

  useEffect(() => {
    if (
      !isAuthenticated &&
      inProgress === InteractionStatus.None &&
      token !== "null"
    ) {
      // user is not authenticated

      instance
        .loginRedirect({
          scopes: ["openid", "profile"],
        })
        .then(() => {
          navigateToUrl("/");
        })
        .catch((e) => {});
    }

    if (token !== "null" && decodeToken<any>(token)?.exp <= moment().unix()) {
      instance.logoutRedirect(logoutRequest);
    }

    // eslint-disable-next-line
  }, [isAuthenticated, inProgress, instance]);

  if (!token) {
    return <Loading text="Authenticating" />;
  }

  return <>{children}</>;
};

export default Protected;
