import React from "react";
import MuiButton from "@mui/material/Button";
import { ButtonProps } from "./types";
import { useTheme } from "@mui/material/styles";

const Button: React.FC<ButtonProps> = ({
  type = "button",
  children,
  onClick,
  borderRadius,
  color,
  margin,
  padding,
  fontSize,
  props,
  sx,
  startIcon,
  width,
  disabled,
}) => {
  const theme = useTheme();
  const sxValue = {
    background: color || theme.palette.primary.light,
    color: theme.palette.common.white,
    padding: padding,
    margin: margin,
    fontSize: fontSize,
    width: width,
    borderRadius: borderRadius,
    textTransform: "none",
    "&:hover": {
      backgroundColor: color || theme.palette.primary.light,
    },
    ...sx,
  };
  return (
    <MuiButton
      type={type}
      onClick={onClick}
      sx={sxValue}
      startIcon={startIcon}
      disabled={disabled}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
