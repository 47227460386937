import { FC } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

type LoadingProps = {
  text?: string;
};

const Loading: FC<LoadingProps> = (props) => {
  const { text = "Loading" } = props;
  return (
    <Box
      id="loading-screen"
      position="absolute"
      zIndex="9999"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100vw"
      top="0"
      sx={{
        backgroundColor: "white",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
        <Typography variant="h6" component="div" sx={{ ml: "1rem" }}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default Loading;
