import { useContext } from "react";
import CustomPropsContext from "../../../config/Context";
import { create } from "zustand";

export function clientsEndpoints() {
  const { authStore } = useContext(CustomPropsContext);
  const useAuthStore = create(authStore);
  const token = useAuthStore((state) => state.token);
  const faToken = useAuthStore((state) => state.faToken);
  const apimKey = useAuthStore((state) => state.apimKey);
  const serverUrl = useAuthStore((state) => state.serverUrl);

  const headers = {
    "Content-Type": "application/json",
    "X-Functions-Key": faToken || "",
    "Ocp-Apim-Subscription-Key": apimKey || "",
    Authorization: `Bearer ${token}`,
  };

  const getAllClients = async (query: string) => {
    const request = `${serverUrl}/clients`;
    return await fetch(`${request}?${query}&limit=9999`, {
      headers: headers,
    }).then((res) => res.json());
  };

  const getClientsByOwnerId = async (ownerId: string) => {
    const request = `${serverUrl}/owner/clients/${ownerId}`;
    return await fetch(`${request}?limit=9999`, {
      headers: headers,
    }).then((res) => res.json());
  };
  const getClientsByUserId = async (query: string) => {
    const request = `${serverUrl}/user/clients`;
    return await fetch(`${request}?${query}`, {
      headers: headers,
    }).then((res) => res.json());
  };

  const getClientsByProjectId = async (id: string) => {
    const url = `${serverUrl}/projects/${id}/clients`;
    return await fetch(url, { headers: headers }).then((res) => res.json());
  };

  return {
    getAllClients,
    getClientsByOwnerId,
    getClientsByUserId,
    getClientsByProjectId,
  };
}
