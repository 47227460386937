import { FC } from "react";
import { create } from "zustand";
import { ThemeProvider } from "@mui/material";
import { type RootProps } from "./types";
import { theme } from "./config";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ErrorIcon } from "./assets/icons/icons";
import { Authorization, AuthorizationProvider, Navigation } from "./lib";
import "./styles/root.css";
import { Configuration, LogLevel } from "@azure/msal-browser";
import SessionExpired from "./pages/SessionExpired/SessionExpired";
import { toast } from "react-toastify";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      throwOnError: false, // Set this to false to not throw errors in the UI when a query fails
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      toast.error(
        "An unexpected error occurred. Please try refresh your page.",
        {
          autoClose: 3000,
          icon: ErrorIcon,
          toastId: "api-error-toast",
        }
      );
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      toast.error(
        "An unexpected error occurred. Please try refresh your page.",
        {
          autoClose: 3000,
          icon: ErrorIcon,
          toastId: "api-error-toast",
        }
      );
    },
  }),
});

const RootComponent: FC<RootProps> = (props) => {
  const { authStore, accountPanelStore } = props;
  const useAuthStore = create(authStore);
  const useAccountPanelStore = create(accountPanelStore);
  const appId = useAuthStore((state) => state.functionAppId);
  const authority: string = useAuthStore((state) => state.functionAuthority);
  const userFlow: string = useAuthStore((state) => state.functionUserFlow);
  const layoutObject = useAuthStore((state) => state.layoutObject);

  const redirectUri: string = useAuthStore(
    (state) => state.functionRedirectUri
  );
  const authorityDomain: string = useAuthStore(
    (state) => state.functionAuthorityDomain
  );
  const token = useAuthStore((state) => state.token);
  const setToken = useAuthStore((state) => state.setToken);

  const showAccountPanel = useAccountPanelStore(
    (state) => state.showAccountPanel
  );
  const setShowAccountPanel = useAccountPanelStore(
    (state) => state.setShowAccountPanel
  );

  const msalConfig = {
    auth: {
      clientId: appId,
      authority: `${authority}/${userFlow}`,
      knownAuthorities: [authorityDomain],
      redirectUri: redirectUri,
      postLogoutRedirectUri: redirectUri,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      iframeHashTimeout: 10000,
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            default:
              return;
          }
        },
      },
    },
  } satisfies Configuration;

  if (token === "null") {
    return <SessionExpired />;
  }
  return (
    <AuthorizationProvider msalConfig={msalConfig}>
      <ThemeProvider theme={theme}>
        <Authorization appId={appId} token={token} setToken={setToken}>
          <QueryClientProvider client={queryClient}>
            <Navigation
              token={token}
              showAccountPanel={showAccountPanel}
              setShowAccountPanel={setShowAccountPanel}
              paths={layoutObject}
            />
          </QueryClientProvider>
        </Authorization>
      </ThemeProvider>
    </AuthorizationProvider>
  );
};

export default RootComponent;
