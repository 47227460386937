import { MouseEventHandler, useEffect, useState, type FC } from "react";
import { navigateToUrl } from "single-spa";
import {
  AppBar,
  Link,
  LinkProps,
  Stack,
  Toolbar,
  useTheme,
} from "@mui/material";
import { Welcome } from "./components/molecules";
import Logo from "../assets/MyCRLogo.png";

const handleNavigate: MouseEventHandler<HTMLAnchorElement> = (event) => {
  event.preventDefault();
  navigateToUrl(event.currentTarget.href);
};

const getLinkProps = (
  href: string,
  path: string,
  onClick?: () => void
): LinkProps => ({
  href,
  onClick: (event) => {
    handleNavigate(event);
    if (onClick) onClick();
  },
});

const links = [
  {
    href: "/",
    text: "Home",
  },
  {
    href: "/projects",
    text: "Projects",
    match: true,
  },
];

type NavigationProps = {
  token: string;
  showAccountPanel: boolean;
  setShowAccountPanel: (showAccountPanel: boolean) => void;
  paths: any[];
};

const Navigation: FC<NavigationProps> = (props) => {
  const { showAccountPanel, setShowAccountPanel, token, paths } = props;
  const theme = useTheme();
  const [path, setPath] = useState(window.location.pathname);
  const activeTab = path.startsWith("/projects") ? "/projects" : "/";

  useEffect(() => {
    window.addEventListener("single-spa:routing-event", () => {
      setPath(window.location.pathname);
    });

    return () => {
      window.removeEventListener("single-spa:routing-event", () => {
        setPath(window.location.pathname);
      });
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      component="header"
      sx={{ padding: "0 !important" }}
    >
      <Toolbar
        component="nav"
        sx={{
          backgroundColor: theme.palette.common.white,
          color: "#262637",
          height: "3rem",
          justifyContent: "space-between",
          minHeight: "unset !important",
          paddingX: {
            md: "2.625rem",
          },
        }}
      >
        <Link
          {...getLinkProps("/", path)}
          underline="none"
          sx={{ lineHeight: 0 }}
        >
          <img src={Logo} alt="MyCR Logo" height="31px" />
        </Link>
        <Welcome
          token={token}
          showAccountPanel={showAccountPanel}
          setShowAccountPanel={setShowAccountPanel}
        />
      </Toolbar>
      <Stack
        component="nav"
        direction="row"
        sx={{
          boxSizing: "border-box",
          backgroundColor: "#053747",
          color: theme.palette.common.white,
          alignItems: "center",
          justifyContent: "flex-start",
          minHeight: "unset",
          height: "2.5rem",
        }}
      >
        {paths.map((link) => (
          <Link
            key={link.path}
            {...getLinkProps(link.path, path)}
            sx={{
              boxSizing: "border-box",
              paddingX: "2rem",
              paddingY: "0.5rem",
              fontWeight:
                link.path === activeTab || paths.length === 1
                  ? "bold"
                  : "normal",
              color: "inherit",
              fontSize: "1rem",
              lineHeight: "1.5rem",
              borderBottomWidth: "4px",
              borderBottomStyle: "solid",
              borderBottomColor:
                link.path === activeTab || paths.length === 1
                  ? "#698791"
                  : "transparent",
            }}
            underline="none"
          >
            {link.title}
          </Link>
        ))}
      </Stack>
    </AppBar>
  );
};

export default Navigation;
