import {
  type ComponentType,
  Fragment,
  useState,
  type FC,
  type ReactNode,
} from "react";
import {
  Menu as MUIMenu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

type Props = {
  Trigger: ComponentType<{ onClick: (event: any) => void }>;
  items: Array<{ icon: ReactNode; text: string; onClick: () => void }>;
};
const Menu: FC<Props> = (props) => {
  const { Trigger, items } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: any) => setAnchorEl(true);
  const handleClose = () => setAnchorEl(null);

  return (
    <Fragment>
      <Trigger onClick={handleOpen} />
      <MUIMenu
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-paper": {
            minWidth: "200px",
            backgroundColor: "#FFFFFF",
            borderRadius: "0.25rem",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "#DFDFDF",
          },
          "& .MuiMenu-list": {
            padding: "0",
          },
          "& .MuiMenuItem-root": {
            padding: "0.40625rem 1rem",
            "&:hover": {
              backgroundColor: "#F2F2F2",
            },
          },
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              item.onClick();
            }}
          >
            <ListItemIcon
              sx={{
                div: {
                  width: "1.25rem",
                  height: "1.25rem",
                },
                svg: {
                  width: "1.25rem",
                  height: "1.25rem",
                  fill: "#242D35",
                },
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              color="#242D35"
              sx={{
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                fontWeight: 400,
              }}
            >
              {item.text}
            </ListItemText>
          </MenuItem>
        ))}
      </MUIMenu>
    </Fragment>
  );
};

export default Menu;
