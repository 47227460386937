import React from "react";
import { StoreApi } from "zustand";
import { AccountPanelStore, AuthStore } from "../types";

export type AppPropsContext = {
  authStore: StoreApi<AuthStore>;
  accountPanelStore: StoreApi<AccountPanelStore>;
};

const CustomPropsContext = React.createContext<AppPropsContext>(null);

export default CustomPropsContext;
