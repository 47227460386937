export const truncateText = (
  text: string,
  maxLength: number,
  ellipsis = "...",
  includeEllipsis = true
) => {
  if (text?.length > maxLength) {
    return (
      text.slice(0, maxLength - (includeEllipsis ? 0 : ellipsis.length)) +
      ellipsis
    );
  }
  return text;
};
