import type { FC } from "react";
import { Divider, Modal, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { TCs } from "./TCs";
import Button from "../../atoms/button/Button";
import theme from "../../../../theme/Theme";

type Props = {
  open: boolean;
  handleClose: () => void;
};

export const TermsAndConditionsModal: FC<Props> = (props) => {
  const { open, handleClose } = props;
  const customTheme = theme();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="terms-and-conditions-modal"
      aria-describedby="terms-and-conditions"
      sx={{
        "&.MuiModal-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Stack width="704px" bgcolor="white" gap={2}>
        <Stack borderTop={`6px solid ${customTheme.palette.primary.main}`}>
          <Typography
            fontSize="24px"
            fontWeight={600}
            color={customTheme.palette.primary.main}
            px={5}
            py={3}
          >
            Terms and conditions
          </Typography>
          <Divider />
        </Stack>
        <Stack height="350px">
          <TCs />
        </Stack>
        <Stack flexDirection="row" justifyContent="end" px={5} pb={2}>
          <Button
            color={customTheme.palette.primary.main}
            fontSize="16px"
            padding="10px 35px"
            onClick={handleClose}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

TermsAndConditionsModal.displayName = "TermsAndConditionsModal";

export default TermsAndConditionsModal;
