import { Fragment, useState, type FC } from "react";
import { useMsal } from "@azure/msal-react";
import { IconButton, Stack, Typography } from "@mui/material";
import { ReactSVG } from "react-svg";
import Menu from "./Menu";
import ConfirmationDialog from "./confirmationDialog/ConfirmationDialog";
import { decodeToken } from "react-jwt";
import { DecodedToken } from "../../../types/Token";
import SwitchAccount from "../organisms/SwitchAccount/SwitchAccount";

type WelcomeProps = {
  token: string;
  showAccountPanel: boolean;
  setShowAccountPanel?: (showAccountPanel: boolean) => void;
};

const Welcome: FC<WelcomeProps> = (props) => {
  const { showAccountPanel, setShowAccountPanel, token } = props;
  const [showLogoutDialog, setShowLogoutDialog] = useState<boolean>(false);
  const { accounts, instance } = useMsal();
  const decoded = decodeToken<DecodedToken>(token);

  const handleAccountPanel = () => {
    setShowAccountPanel(true);
  };

  const handleLogOut = () => {
    sessionStorage.clear();
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: "/",
    };
    instance.logoutRedirect(logoutRequest);
  };

  return (
    <Fragment>
      <Stack direction="row" gap="0.5rem" alignItems="center">
        <Typography
          fontSize="0.875rem"
          fontStyle="normal"
          fontWeight={400}
          lineHeight="1.5rem"
          color="#262637"
        >
          Welcome back, <b>{decoded.name}</b>
        </Typography>
        <Menu
          items={
            decoded.email.includes("@controlrisks.com") ||
            decoded.companyId === "001"
              ? [
                  {
                    icon: <ReactSVG src="/icons/switch-account.svg" />,
                    text: "Switch account",
                    onClick: () => handleAccountPanel(),
                  },
                  {
                    icon: <ReactSVG src="/icons/log-out.svg" />,
                    text: "Log out",
                    onClick: () => setShowLogoutDialog(true),
                  },
                ]
              : [
                  {
                    icon: <ReactSVG src="/icons/log-out.svg" />,
                    text: "Log out",
                    onClick: () => setShowLogoutDialog(true),
                  },
                ]
          }
          Trigger={(props) => (
            <IconButton
              size="large"
              sx={{
                padding: 0,
                backgroundColor: "unset",
                boxShadow: "unset",
                height: "1.25rem",
                width: "1.25rem",

                "&:hover": {
                  backgroundColor: "unset",
                  boxShadow: "unset",
                },
                "div>div": {
                  height: "1.25rem",
                  width: "1.25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                svg: {
                  height: "0.625rem",
                  width: "1.25rem",
                },
              }}
              {...props}
            >
              <ReactSVG src="/icons/chevron-down.svg" />
            </IconButton>
          )}
        />
      </Stack>
      <ConfirmationDialog
        showDialog={showLogoutDialog}
        title="Log out"
        message="Are you sure you want to log out?"
        handleClose={() => setShowLogoutDialog(false)}
        handleConfirm={handleLogOut}
        closeText="Cancel"
        confirmText="Log out"
      />
      {decoded.companyId === "001" ? (
        <SwitchAccount
          showAccountPanel={showAccountPanel}
          setShowAccountPanel={setShowAccountPanel}
        />
      ) : null}
    </Fragment>
  );
};

export default Welcome;
