import type { FC, PropsWithChildren } from "react";
import { MsalProvider } from "@azure/msal-react";
import {
  PublicClientApplication,
  type Configuration,
} from "@azure/msal-browser";

const AuthorizationProvider: FC<
  PropsWithChildren<{ msalConfig: Configuration }>
> = (props) => {
  const { msalConfig, children } = props;
  const msalInstance = new PublicClientApplication(msalConfig);
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export default AuthorizationProvider;
