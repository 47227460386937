import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import CustomPropsContext, { AppPropsContext } from "./config/Context";

const lifecycles = singleSpaReact<AppPropsContext>({
  React,
  ReactDOM,
  rootComponent: (props) => {
    return (
      <CustomPropsContext.Provider value={props}>
        <Root
          authStore={props.authStore}
          accountPanelStore={props.accountPanelStore}
        />
      </CustomPropsContext.Provider>
    );
  },
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
